import React from 'react'


export default (props) => {
  //const page = usePage();
  let getId = (url) => {
    if (url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11)
        ? match[2]
        : null;
    } else {
      return null
    }
    
  }
  let autoplay = ''
  if(props.autoplay==true) {
    autoplay = '?autoplay=1'
  }
      
  const videoId = getId(props.videoUrl);
  const iframeMarkup = '<iframe className="embed-responsive-item" src="//www.youtube.com/embed/' 
      + videoId + autoplay + '" frameborder="0" allowfullscreen></iframe>';

  return(
    <div dangerouslySetInnerHTML={{__html: iframeMarkup}} className="embed-responsive embed-responsive-16by9" />
  )
}
